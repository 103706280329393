import { createContext, useState, useEffect } from "react";
import axios from "axios";

export const countryContext = createContext();

export const CountryProvider = (props) => {
  const [isNepal, setIsNepal] = useState();
  const [countryCodeContext, setCountryCodeContext] = useState();
  const [countryNameContext, setCountryNameContext] = useState();
  const [countryCurrencyContext, setCountryCurrencyContext] = useState();
  const [exchangeRate, setExchangeRate] = useState();
  //Get country symbol
  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((res) => {
        setCountryCodeContext(res.data.country_code);
        setCountryNameContext(res.data.country_name);
        setCountryCurrencyContext(res.data.currency);
        // If country code is NP, set isNepal true
        if (res.data.country_code === "NP") {
          setIsNepal(true);
        } else {
          setIsNepal(false);
        }
      })
      .catch((err) => {});
  }, []);

  return (
    <countryContext.Provider
      value={{
        isNepal,
        countryCodeContext,
        setCountryCodeContext,
        countryNameContext,
        setCountryNameContext,
        countryCurrencyContext,
        setIsNepal,
        exchangeRate,
        setCountryCurrencyContext,
        setExchangeRate,
      }}
    >
      {props.children}
    </countryContext.Provider>
  );
};
