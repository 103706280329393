import { countryContext } from "../../context/CountryName/countryContext";
import countryList from "../country/country.json";
import { useContext } from "react";
import countryToCurrency from "country-to-currency";
import Select from "react-select";
import { useState } from "react";
import CountrySelectStyle from "../country/style.module.css";
const CountrySelect = () => {
  const {
    countryCodeContext,
    setCountryCodeContext,
    setIsNepal,
    setCountryCurrencyContext,
    setCountryNameContext,
  } = useContext(countryContext);
  const [menuOpen, setMenuOpen] = useState("");

  const changeCountry = (selectedOption) => {
    const countryCode = selectedOption.value;
    setCountryCodeContext(selectedOption.value);
    const countryCurrency = countryToCurrency[selectedOption.value];
    setCountryCurrencyContext(countryCurrency);
    setCountryNameContext(selectedOption.label);

    // // Set isNepal = false if other selected
    if (countryCode !== "NP") {
      setIsNepal(undefined);
    } else {
      setIsNepal(true);
    }
  };

  const options = [];

  countryList.map((country, index) => {
    if (countryCodeContext == country.code) {
    }
    options.push({ value: country.code, label: country.name });
  });

  return (
    <>
      {/* Css files in popup.css  */}
      <div className={CountrySelectStyle.container}>
        <img
          alt={countryCodeContext + " flag"}
          src={`https://flagsapi.com/${countryCodeContext}/flat/64.png`}
        />

        <div>
          <Select
            styles={{
              input: (base) => ({
                ...base,
                marginTop: "-8px",
              }),

              option: (base) => ({
                ...base,
                textAlign: "left",
                padding: "-5px 0",
                paddingLeft: "10px",
              }),

              placeholder: (provided) => ({
                ...provided,
                color: "rgb(49, 49, 49)",
                position: "absolute",
                top: "-5px",
                right: "10px",
              }),

              singleValue: (provided) => ({
                ...provided,
                color: "rgb(49, 49, 49)",
                position: "absolute",
                top: "0px",
                right: "10px",
              }),

              menu: (provided) => ({
                ...provided,
                width: "200px",
                marginLeft: "-50px",
                maxHeight: "600px",
              }),

              indicatorsContainer: () => ({
                display: "none",
              }),
              indicatorSeparator: () => ({
                display: "none",
              }),

              valueContainer: (provided) => ({
                ...provided,
                padding: "0px",
              }),

              control: (provided) => ({
                ...provided,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                boxShadow: "none",
                height: "30px",
                width: "40px",
                fontSize: "0.8em",
                color: "grey",
                background: "transparent",
                marginRight: "-6px",
              }),
            }}
            options={options}
            placeholder={countryCodeContext}
            value={countryCodeContext}
            onChange={changeCountry}
            isSearchable={true}
            onMenuOpen={() => setMenuOpen("menuOpen")}
            onMenuClose={() => setMenuOpen("")}
          />
        </div>
      </div>
    </>
  );
};

export default CountrySelect;
