// context
import { useLayoutUpdate } from "../../../../context/LayoutProvider/LayoutProvider";
import CountrySelect from "../../../../data/country/CountrySelect";

// components
import NioButton from "../../../NioButton/NioButton";

export default function HeaderAction({
  buttonContent,
  className,
  nioBtnClasses,
  nioToggleClasses,
}) {
  const layoutUpdate = useLayoutUpdate();

  return (
    <div className={className}>
      <ul className="nk-btn-group sm justify-content-center">
        <li>
          {" "}
          <CountrySelect />
        </li>
        <li className="d-none d-md-block">
          <NioButton
            className="btn-primary"
            label={"Login / Signup"}
            onClick={() => {
              window.open("/auth/login", "_blank");
            }}
          />

          {/* </NioButton> */}
        </li>
        <li className="nk-navbar-toggle">
          <NioButton
            icon="menu"
            className={nioToggleClasses}
            onClick={layoutUpdate.headerMobile}
            href={"/auth/login"}
          />
        </li>
      </ul>
    </div>
  );
}
