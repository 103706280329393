import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Playstore1 from "../../../assets/images/playstore.png";
import Playstore2 from "../../../assets/images/playstore1.png";

// layout
import AppLayout from "../../../layouts/AppLayout/AppLayout";

// components
import {
  NioButton,
  NioMedia,
  NioBrand,
  NioCount,
  NioIcon,
  NioSection,
  NioCard,
} from "../../../components";

// section content
import NioSubscribeField from "../../../components/Forms/NioSubscribeField/NioSubscribeField";

function index() {
  return (
    <AppLayout variant={2} title="RestroX | Restaurent Software">
      {/*   Banner Section Start  */}
      <section className="nk-banner nk-banner-analytics overflow-hidden">
        <div className="nk-mask blur-1 left top"></div>
        <div className="nk-mask blur-1 right bottom"></div>
        <div className="nk-banner-wrap pb-0 pb-md-8 overflow-hidden">
          <Container>
            <Row className="justify-content-center">
              <Col lg={10} xl={7}>
                <div className="nk-banner-content text-center">
                  <span className="d-inline-block fs-14 text-uppercase text-primary fw-semibold mb-2">
                    All in One Restaurant Software
                  </span>
                  <h1 className="display-6 mb-2 mb-lg-4">
                    <strong style={{ color: "#DA0819" }}>One app</strong> is
                    enough
                    <br /> for{" "}
                    <span className="title-shape title-shape-1">
                      <strong style={{ color: "#DA0819" }}>Restaurant</strong>
                    </span>
                  </h1>
                  <p className="fs-20 m-0">
                    {" "}
                    RestroX is not just a POS system. It is an ultimate
                    restaurant management software gathered with high-end
                    features compacted all in one app.{" "}
                  </p>
                  <ul className="nk-btn-group justify-content-center pt-4 pt-md-5">
                    {/* <li>
                      <NioButton
                        href="/index-analytics"
                        className="btn-primary"
                        label="Get Started"
                      />
                    </li> */}
                    <li>
                      <a
                        href="https://play.google.com/store/search?q=restrox&c=apps"
                        target="_blank"
                      >
                        {" "}
                        <img
                          src={Playstore1}
                          style={{
                            width: " 160.86px",
                            height: " 48px",
                            borderRadius: " 10.38px",
                          }}
                        />
                      </a>{" "}
                      <a
                        className="mx-2"
                        href="https://apps.apple.com/np/app/restrox-restaurant-manager/id1611549881"
                        target="_blank"
                      >
                        <img
                          src={Playstore2}
                          style={{
                            width: " 160.86px",
                            height: " 48px",
                            borderRadius: " 10.38px",
                          }}
                        />
                      </a>
                      <NioButton
                        href="https://web.restrox.co/register"
                        className="btn-outline-primary "
                        label="Get Started"
                        target="_blank"
                      />
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={9} lg={10} xl={9}>
                <div className="pt-6 mt-md-2 pb-7 pb-md-0">
                  <div className="position-relative nk-banner-img nk-banner-img-s1">
                    <img
                      className="thumb-base border rounded-3"
                      src="images/thumb/a.jpg"
                      alt="thumb-content"
                    />
                    <img
                      className="thumb thumb-1 animate animate-shakeY animate-duration-12"
                      src="images/thumb/a-1.png"
                      alt="thumb-content"
                    />
                    <img
                      className="thumb thumb-2 animate animate-shakeY animate-duration-12 animate-delay-1"
                      src="images/thumb/a-2.png"
                      alt="thumb-content"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/*  Banner Section End  */}

      {/*  Brand Section Start  */}
      <NioSection className="nk-brand-section">
        <NioSection.Content>
          <Row className="justify-content-center">
            <Col lg={10} xl={8}>
              <div className="text-center">
                <h6 className="fw-normal text-light mb-5">
                  Trusted by 4,000+ Restaurant around the world
                </h6>
                <Row className="gy-2 gy-lg-0 align-items-center justify-content-center">
                  {["a", "b", "c", "d", "e"]?.map((brand, idx) => (
                    <Col xs={4} sm={3} lg={2} key={idx}>
                      <div className="nk-brand">
                        <Link to="#">
                          <img
                            src={`images/brands/${brand}.png`}
                            alt={`brand-${brand}`}
                          />
                        </Link>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Brand Section End  */}

      {/*  Feature Section Start  */}
      <NioSection masks={["blur-1 left top", "blur-1 right bottom"]}>
        <NioSection.Head alignX="center">
          <span className="fs-14 text-uppercase text-primary fw-semibold mb-2">
            Features
          </span>
          <h2>Discover Powerful Features</h2>
          <p className="fs-20">
            It’ easy to manage your chain restaurants. Monitor your restaurant's
            activity from anywhere through a single system.
          </p>
        </NioSection.Head>
        <NioSection.Content>
          <Row className="gy-5">
            <Col lg={6}>
              <NioCard className="border-0 bg-blue-50">
                <NioCard.Body>
                  <div className="nk-feature-block-content">
                    <NioMedia
                      size="lg"
                      variant="primary"
                      rounded
                      icon="pie-2-fill"
                      className="mb-3 mb-lg-5"
                    />
                    <h4>Order</h4>
                    <p className="fs-16 mb-0">
                      From orders by QR Menu, every staff gets mobile app logins
                      from where they can take orders. Manage every KOT | BOT |
                      COT in one place with smooth operating mobile app. Never
                      miss any orders!
                    </p>
                    <NioButton
                      href="/index-analytics"
                      icon="arrow-right after"
                      className="btn-link mt-2 mt-md-5"
                      label="Get Started"
                    />
                  </div>
                </NioCard.Body>
                <div className="text-start">
                  <img
                    src="images/features/a.png"
                    alt="feature"
                    className="pe-3 pe-md-7"
                  />
                </div>
              </NioCard>
            </Col>
            <Col lg={6}>
              <NioCard className="border-0 bg-blue-50">
                <NioCard.Body>
                  <div className="nk-feature-block-content">
                    <NioMedia
                      size="lg"
                      variant="danger text-white"
                      rounded
                      icon="files-fill"
                      className="text-white mb-3 mb-lg-5"
                    />
                    <h4>Live Sales Reporting</h4>
                    <p className="fs-16 mb-0">
                      Get insights related to your overall sales in real-time,
                      and on a regular basis from anywhere at any time through
                      the app. Keep track of all function and Operations
                      happening at your Restaurant.
                    </p>
                    <NioButton
                      href="/index-analytics"
                      icon="arrow-right after"
                      label="Get Started"
                      className="btn-link mt-2 mt-md-5"
                    />
                  </div>
                </NioCard.Body>
                <div className="text-end">
                  <img
                    src="images/features/b.png"
                    alt="feature"
                    className="ps-3 ps-md-7"
                  />
                </div>
              </NioCard>
            </Col>
            <Col md={6} xl={4}>
              <NioCard className="border-0 bg-blue-50">
                <NioCard.Body>
                  <NioMedia
                    size="lg"
                    variant="warning"
                    rounded
                    icon="user-switch-fill"
                    className="text-white mb-3 mb-lg-5"
                  />
                  <h4>Delivery System</h4>
                  <p className="fs-16">
                    Grow your customer base with our advance delivery portal.
                    You will get your own delivery link from where customer can
                    order.
                  </p>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} xl={4}>
              <NioCard className="border-0 bg-blue-50">
                <NioCard.Body>
                  <NioMedia
                    size="lg"
                    variant="danger text-white"
                    rounded
                    icon="tree-structure-fill"
                    className="text-white mb-3 mb-lg-5"
                  />
                  <h4>Automatic Inventory</h4>
                  <p className="fs-16">
                    Setting up and managing your inventory is easier than ever.
                    Manage your stocks. Keep track of purchase invoice and
                    suppliers.
                  </p>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} xl={4}>
              <NioCard className="border-0 bg-blue-50">
                <NioCard.Body>
                  <NioMedia
                    size="lg"
                    variant="primary"
                    rounded
                    icon="puzzle-fill"
                    className="mb-3 mb-lg-5"
                  />
                  <h4>Digital QR Menu</h4>
                  <p className="fs-16">
                    Our system creates unique QR codes for each table in your
                    restaurant. From where customer can make their own orders.
                  </p>
                </NioCard.Body>
              </NioCard>
            </Col>
          </Row>
          <div className="text-center pt-7">
            <ul className="nk-btn-group justify-content-center">
              <li>
                <NioButton
                  href="/auth/sign-up"
                  className="btn-primary"
                  label="Get Started"
                />
              </li>
              <li>
                <NioButton
                  href="/features"
                  className="btn-outline-primary"
                  label="Explore All Features"
                />
              </li>
            </ul>
          </div>
        </NioSection.Content>
      </NioSection>
      {/*  Feature Section End  */}

      {/*  Works Section Start  */}
      <NioSection className="nk-section-cta">
        <NioSection.Head alignX="center">
          <span className="fs-14 text-uppercase text-primary fw-semibold mb-2">
            HOW IT WORKS?
          </span>
          <h2>Easy Process To Get Started</h2>
          <p className="fs-20">
            Discover how it works by simple real life based solutions and data
            analysis techniques.
          </p>
        </NioSection.Head>
        <NioSection.Content>
          <Row className="align-items-center flex-row-reverse justify-content-between">
            <Col lg={6}>
              <div className="nk-cta-img-wrap bg-primary-gradient rounded-4 px-4 pt-4 mb-7 mb-lg-0 ms-lg-7 overflow-hidden">
                <img src="images/cta/a.png" alt="thumb" />
              </div>
            </Col>
            <Col lg={6}>
              <ul className="gap gy-5 gy-lg-7">
                <li className="media-group gap-3 gap-md-5 flex-column flex-sm-row">
                  <NioMedia size="lg" rounded border icon="laptop-fill" />

                  <div className="media-text m-0">
                    <h4 className="text-capitalize mb-2">
                      Download & Create Accounts
                    </h4>
                    <p>
                      Getting started with our system is as easy as eating
                      chilled ice-cream. Just open your device and download then
                      create account.
                    </p>
                  </div>
                </li>
                <li className="media-group gap-3 gap-md-5 flex-column flex-sm-row">
                  <NioMedia size="lg" rounded border icon="google-play-store" />

                  <div className="media-text m-0">
                    <h4 className="text-capitalize mb-2">
                      Invite staffs - Add Tables - Setup Menus
                    </h4>
                    <p>
                      After Creating your restaurant’s account in RestroX, you
                      need to setup your Table and Menus. By inviting your
                      staffs, you can work together!
                    </p>
                  </div>
                </li>
                <li className="media-group gap-3 gap-md-5 flex-column flex-sm-row">
                  <NioMedia size="lg" rounded border icon="browser-fill" />

                  <div className="media-text m-0">
                    <h4 className="text-capitalize mb-2">
                      Start taking Your Orders & Get Rich
                    </h4>
                    <p>
                      Taking orders is where we focus most. We offer various
                      ways to make orders. You can figure it out on your own.
                    </p>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>

      {/*  Works Section End  */}

      {/*  Video Section Start  */}
      <NioSection
        className="nk-video-section"
        masks={["blur-1 left top", "blur-1 right bottom"]}
      >
        <NioSection.Head alignX="center">
          <span className="fs-14 text-uppercase text-primary fw-semibold mb-2">
            overview
          </span>
          <h2>All-In-One Restaurent Software</h2>
          <p className="fs-20">
            It is enclosed with all the multifaceted requirements from orders,
            menus, sales, inventory, staff, delivery, and accounting management
            in a single system.
          </p>
        </NioSection.Head>
        <NioSection.Content>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="nk-video rounded-3 bg-blue-50 ">
                <div className="nk-mask shape-1 overflow-hidden rounded-4"></div>
                <div className="nk-video-inner">
                  <div className="nk-video-logo p-4 d-none d-sm-block">
                    <NioBrand logo="s1" variant="dark" />
                  </div>
                  <div className="nk-video-content">
                    <div className="nk-video-img overflow-hidden pt-4">
                      <img
                        className="parallax-init mb-n6 mb-sm-n4"
                        data-scale="1.25"
                        data-orientation="down"
                        src="images/video/a.png"
                        alt=""
                      />
                    </div>
                    <div className="nk-video-btn">
                      <NioMedia
                        size="lg"
                        icon="play-fill"
                        rounded
                        variant="primary"
                        lightboxSrc="https://www.youtube.com/watch?v=pVE92TNDwUk"
                        className="shadow-sm animate animate-infinite animate-pulse animate-duration-1"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center pt-5 pt-md-7">
                <ul className="nk-btn-group justify-content-center">
                  <li>
                    <NioButton
                      href="/auth/login"
                      className="btn-primary"
                      label="Get Started"
                    />
                  </li>
                  <li>
                    <NioButton
                      href="/about"
                      className="btn-outline-primary"
                      label="Learn More"
                    />
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center justify-content-xxl-between align-items-center pt-5 pt-md-7">
            <Col lg={7} xl={6}>
              <div className="nk-section-head pb-xxl-0 text-center text-xxl-start">
                <h2 className=" mb-0">
                  More Than 4k+ People Around The World Are Already Using
                </h2>
              </div>
            </Col>
            <Col lg={8} xl={6}>
              <div className="d-flex gap-2 flex-wrap justify-content-center justify-content-lg-between text-xxl-end">
                <div className="mb-2 mb-xl-0 text-center text-lg-end">
                  <h3 className="mb-1">
                    <NioCount end="4" suffix="K+" />
                  </h3>
                  <p className="fs-20"> Active Downloads</p>
                </div>
                <div className="mb-2 mb-xl-0 text-center text-lg-end">
                  <h3 className="mb-1">
                    <NioCount end={4} suffix=".86" />
                  </h3>
                  <p className="fs-20"> Average Rating</p>
                </div>
                <div className="mb-2 mb-xl-0 text-center text-lg-end">
                  <h3 className="mb-1">
                    <NioCount end={4} suffix="K+" />
                  </h3>
                  <p className="fs-20"> Active Users</p>
                </div>
              </div>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>

      {/*  Video Section End  */}

      {/*  Testimonial Section Start  */}
      <NioSection className="nk-section-testimonial py-7 py-lg-120">
        <NioSection.Head alignX="center">
          <span className="d-inline-block fs-16 text-uppercase text-success-alt fw-semibold mb-2">
            Customers Review
          </span>
          <h2 className="mb-0">
            Stories <span className="title-shape title-shape-3">From Our</span>
            Customers
          </h2>
        </NioSection.Head>
        <NioSection.Content>
          <Row>
            <Col lg={4}>
              <div className="card-list gy-4">
                <NioCard>
                  <NioCard.Body>
                    <h5 className="fw-semibold mb-2 mb-md-3">
                      We can Order Our Food Very Easily
                    </h5>
                    <p className="small">
                      "RestroX has been a great addition to our restaurent.The
                      digital menu,quick ordering, and mobile app have all been
                      very useful.Highly recommend."
                    </p>

                    <div className="media-group align-items-center pt-2">
                      <NioMedia size="md" rounded img="images/avatar/a.jpg" />

                      <div className="media-text">
                        <h6 className="mb-0 fw-semibold">Sobit Thakuri</h6>
                        <span className="small">Owner,Coffee Ghar</span>
                      </div>
                    </div>
                  </NioCard.Body>
                </NioCard>
                <NioCard>
                  <NioCard.Body>
                    <h5 className="fw-semibold mb-2 mb-md-3">
                      Experience Exceptional Service
                    </h5>
                    <p className="small">
                      "We've been using RestroX for a while and are very happy
                      with it.The Staff app has helped us stay organized and the
                      digital menu,quick ordering,and mobile app have all been
                      useful.Highly recommend."
                    </p>

                    <div className="media-group align-items-center pt-2">
                      <NioMedia size="md" rounded img="images/avatar/b.jpg" />

                      <div className="media-text">
                        <h6 className="mb-0 fw-semibold">Bimal tiwari</h6>
                        <span className="small">
                          MD,Forest Villa
                          <br />
                          Lakeside,Pokhara
                        </span>
                      </div>
                    </div>
                  </NioCard.Body>
                </NioCard>
                <NioCard>
                  <NioCard.Body>
                    <h5 className="fw-semibold mb-2 mb-md-3">
                      “Great service by Nadim thank you
                    </h5>
                    <p className="small">
                      This morning we completed our 10,000th @NioLand
                      conversation! But why is that we love NioLand so much?
                    </p>

                    <div className="media-group align-items-center pt-2">
                      <NioMedia size="md" rounded img="images/avatar/c.jpg" />

                      <div className="media-text">
                        <h6 className="mb-0 fw-semibold">Jane Doe</h6>
                        <span className="small">@cryptomil</span>
                      </div>
                    </div>
                  </NioCard.Body>
                </NioCard>
              </div>
            </Col>
            <Col lg={4}>
              <div className="card-list gy-4">
                <NioCard>
                  <NioCard.Body>
                    <h5 className="fw-semibold mb-2 mb-md-3">
                      Great Service from a expert support system of RestroX{" "}
                    </h5>
                    <p className="small">
                      Restrox has been a great addition to our restaurent.We've
                      been using Restrox for a while now and are very happy with
                      it.The Staff app has made a big difference in our
                      operations.Worth the cost. #Thanks
                    </p>

                    <div className="media-group align-items-center pt-2">
                      <NioMedia size="md" rounded img="images/avatar/d.jpg" />

                      <div className="media-text">
                        <h6 className="mb-0 fw-semibold">Bikash Tiwari</h6>
                        <span className="small">
                          MD,Homeland Restaurent
                          <br />
                          Lakeside,Pokhara
                        </span>
                      </div>
                    </div>
                  </NioCard.Body>
                </NioCard>
                <NioCard>
                  <NioCard.Body>
                    <h5 className="fw-semibold mb-2 mb-md-3">
                      The Services provided are really great and at very
                      reasonable cost.
                    </h5>
                    <p className="small">
                      " Great system Great support.The entire team has been
                      great to work with.The system is fast and friendly.Will be
                      recommending RestroX to my peers Owning a reataurent."
                    </p>

                    <div className="media-group align-items-center pt-2">
                      <div className="media media-md media-circle">
                        <img src="images/avatar/e.jpg" alt="avatar" />
                      </div>

                      <div className="media-text">
                        <h6 className="mb-0 fw-semibold">Prabin Nepali</h6>
                        <span className="small">
                          Owner,Bulldog Bar
                          <br />
                          Lakeside,Pokhara
                        </span>
                      </div>
                    </div>
                  </NioCard.Body>
                </NioCard>
                <NioCard>
                  <NioCard.Body>
                    <h5 className="fw-semibold mb-2 mb-md-3">
                      Just came back to home and should say that it is
                      definitely a great experience.
                    </h5>
                    <p className="small">
                      I do love your app @NioLand - in less than 24 hours I
                      booked a $250 sale. What can I say? I'm a sucker for a
                      good ROI!
                    </p>

                    <div className="media-group align-items-center pt-2">
                      <div className="media media-md media-circle">
                        <img src="images/avatar/f.jpg" alt="avatar" />
                      </div>
                      <div className="media-text">
                        <h6 className="mb-0 fw-semibold">Jhon Doe</h6>
                        <span className="small">@cryptomil</span>
                      </div>
                    </div>
                  </NioCard.Body>
                </NioCard>
              </div>
            </Col>
            <Col lg={4}>
              <div className="card-list gy-4">
                <NioCard>
                  <NioCard.Body>
                    <h5 className="fw-semibold mb-2 mb-md-3">
                      Pricing is amazing for small business around the world.
                    </h5>
                    <p className="small">
                      Restrox has been a great investment for our
                      restaurent.we're using RestroX for a few months and it's
                      made a big difference in our operations.The digital menu
                      and quick ordering features are particularly helpful.Worth
                      the cost.
                    </p>

                    <div className="media-group align-items-center pt-2">
                      <NioMedia size="md" rounded img="images/avatar/g.jpg" />

                      <div className="media-text">
                        <h6 className="mb-0 fw-semibold">Sosiv Bhurtel</h6>
                        <span className="small">Owner,FoodBank Restro.</span>
                      </div>
                    </div>
                  </NioCard.Body>
                </NioCard>
                <NioCard>
                  <NioCard.Body>
                    <h5 className="fw-semibold mb-2 mb-md-3">
                      I had a great time chatting with Vassilena!{" "}
                    </h5>
                    <p className="small">
                      I want to thank @NioLand too!! We have used them for the
                      past 10 years and recently, after an upgrade, had a
                      problem in a magento install, yet they hung in there and
                      the support team worked their magic. Thanks to all!
                    </p>

                    <div className="media-group align-items-center pt-2">
                      <NioMedia size="md" rounded img="images/avatar/h.jpg" />

                      <div className="media-text">
                        <h6 className="mb-0 fw-semibold">Alex Hales</h6>
                        <span className="small">@cryptomil</span>
                      </div>
                    </div>
                  </NioCard.Body>
                </NioCard>
                <NioCard>
                  <NioCard.Body>
                    <h5 className="fw-semibold mb-2 mb-md-3">
                      I tried the Hair mask first and its the best one I've ever
                      tried love it.
                    </h5>
                    <p className="small">
                      One way we're continuing to serve clients remotely is
                      using online chat software by NioLand. Clients visiting
                      our website can click the chat to get connected to a
                      Navigator in real time. Thank you @NioLand for helping us
                      stay connected to our clients during this time!
                      pic.twitter.com/4SEjAtQ0AR
                    </p>

                    <div className="media-group align-items-center pt-2">
                      <NioMedia size="md" rounded img="images/avatar/i.jpg" />

                      <div className="media-text">
                        <h6 className="mb-0 fw-semibold">Mark Hardson</h6>
                        <span className="small">@cryptomil</span>
                      </div>
                    </div>
                  </NioCard.Body>
                </NioCard>
              </div>
            </Col>
          </Row>
          {/* <div className="text-center pt-5">
            <ul className="nk-btn-group justify-content-center">
              <li>
                <NioButton
                  href="/auth/sign-up"
                  className="btn-success-alt"
                  label="Sign Up For Free"
                />
              </li>
              <li>
                <NioButton
                  href="/auth/login"
                  className="btn-success-alt"
                  label="Login To Your account"
                />
              </li>
            </ul>
          </div> */}
        </NioSection.Content>
      </NioSection>

      {/*  Testimonial Section End  */}

      {/*  Blog Section Start  */}
      <NioSection
        className="nk-section-blog"
        masks={["blur-1 left top", "blur-1 right bottom"]}
      >
        <NioSection.Head alignX="center">
          <span className="fs-14 text-uppercase text-primary fw-semibold mb-2">
            OUR BLOG
          </span>
          <h2>Resource Center</h2>
          <p className="fs-20">
            Unlock the potential of our resource center, accessing valuable
            information and insights for your business growth.
          </p>
        </NioSection.Head>

        <NioSection.Content>
          <Row className="gy-5 gy-lg-0">
            <Col md={6} lg={4}>
              <NioCard className="card-blog">
                <NioCard.Body>
                  <div className="card-image">
                    <img src="images/blog/a.jpg" alt="" className="card-img" />
                  </div>
                  <div className="card-content pt-4">
                    <div className="pb-1">
                      <span className="badge rounded-pill text-bg-primary-soft">
                        Growth
                      </span>
                    </div>
                    <h5 className="card-title">
                      <Link to="/blog-details">
                        Why customer retention is the ultimate growth strategy?
                      </Link>
                    </h5>

                    <div className="media-group pt-4 align-items-center">
                      <div className="media media-sm media-circle">
                        <img src="images/avatar/a.jpg" alt="avatar" />
                      </div>

                      <div className="media-text">
                        <span className="lead-text fw-normal">
                          Bikash Tiwari
                        </span>
                        <ul className="nk-list-meta smaller">
                          <li>Feb 10, 2022</li>
                          <li>8 min read</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4}>
              <NioCard className="card-blog">
                <NioCard.Body>
                  <div className="card-image">
                    <img src="images/blog/b.jpg" alt="" className="card-img" />
                  </div>
                  <div className="card-content pt-4">
                    <div className="pb-1">
                      <span className="badge rounded-pill text-bg-primary-soft">
                        Marketing
                      </span>
                    </div>
                    <h5 className="card-title">
                      <Link to="/blog-details">
                        Optimizing your advertising campaigns for higher ROAS
                      </Link>
                    </h5>

                    <div className="media-group pt-4 align-items-center">
                      <div className="media media-sm media-circle">
                        <img src="images/avatar/b.jpg" alt="" />
                      </div>

                      <div className="media-text">
                        <span className="lead-text fw-normal">
                          Sovit Thakuri
                        </span>
                        <ul className="nk-list-meta smaller">
                          <li>Feb 10, 2022</li>
                          <li>8 min read</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4}>
              <NioCard className="card-blog">
                <NioCard.Body>
                  <div className="card-image">
                    <img src="images/blog/c.jpg" alt="" className="card-img" />
                  </div>
                  <div className="card-content pt-4">
                    <div className="pb-1">
                      <span className="badge rounded-pill text-bg-primary-soft">
                        Growth
                      </span>
                    </div>
                    <h5 className="card-title">
                      <Link to="/blog-details">
                        How to build the ultimate tech stack for growth
                      </Link>
                    </h5>

                    <div className="media-group pt-4 align-items-center">
                      <div className="media media-sm media-circle">
                        <img src="images/avatar/c.jpg" alt="" />
                      </div>

                      <div className="media-text">
                        <span className="lead-text fw-normal">
                          Prabin Nepali
                        </span>
                        <ul className="nk-list-meta smaller">
                          <li>Feb 10, 2022</li>
                          <li>8 min read</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
          </Row>
          <div className="text-center pt-5 pt-lg-7">
            <NioButton
              href="/blogs"
              className="btn-outline-primary"
              label="Browse All Articles"
            />
          </div>
        </NioSection.Content>
      </NioSection>
      {/*  Blog Section End  */}

      {/*  CTA Section Start  */}
      <NioSection>
        <NioSection.Content>
          <div className="nk-cta-wrap nk-cta-card bg-primary-gradient rounded-3 is-theme position-relative overflow-hidden">
            <Row className="g-0 align-items-center overflow-hidden">
              <Col xs={{ order: 2 }} lg={{ span: 7, order: 0 }}>
                <div className="nk-block-head-content  mb-0">
                  <h2 className="newsletter-heading text-capitalize h1 m-0 mb-4 mb-lg-7">
                    Start tracking your user analytics to boost your business
                  </h2>
                  <ul className="nk-btn-group flex-wrap ps-1">
                    <li>
                      <NioButton
                        href="/auth/sign-up"
                        className="btn-white text-dark"
                        label="Get Started"
                      />
                    </li>
                    <li>
                      <NioButton
                        href="/pricing"
                        className="btn-outline-white"
                        label="Pricing & Plans"
                      />
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={{ order: 1 }} lg={{ span: 5, order: 0 }}>
                <div className="nk-cta-img-wrap text-end  ps-5 pt-7 pt-lg-0 ps-sm-6 ps-lg-0">
                  <img
                    src="images/thumb/c.png"
                    alt="tracking-thumb"
                    className="me-n1"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </NioSection.Content>
      </NioSection>
      {/*  CTA Section End  */}

      {/*  Newsletter Section Start  */}
      <NioSection className="nk-newsletter-section pb-lg-0">
        <Row className="justify-content-center justify-content-lg-between align-items-center pb-5 border-bottom border-lighter">
          <Col lg={6} xl={4}>
            <div className="nk-newsletter-content text-center text-lg-start pb-5 pb-lg-0">
              <h4 className="text-capitalize">Subscribe to our newsletter</h4>
              <p className="fs-16">
                Join the 4000+ People That Uses this Amazing Product.
              </p>
            </div>
          </Col>
          <Col md={10} lg={6} xl={5}>
            <NioSubscribeField variant="one" />
          </Col>
        </Row>
      </NioSection>
      {/*  Newsletter Section End  */}
    </AppLayout>
  );
}

export default index;
