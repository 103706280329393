import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

// layouts
import AppLayout from "../../../layouts/AppLayout/AppLayout";

import { useData } from "../../../context/DataProvider/DataProvider";
import { ArrowDown, Check } from "react-feather";

import PricingStyle from "./pricing.module.css";
// components
import {
  NioSection,
  NioPricingCard,
  NioBadge,
  NioButton,
  NioIcon,
  NioMedia,
  NioSubscribeField,
} from "../../../components";

export default function Index() {
  const data = useData();
  const contents = data.pricing.tracker.contents;
  const [isSemiAnnually, setIsSemiAnnually] = useState(false);
  const toggleSwitch = () => setIsSemiAnnually(!isSemiAnnually);
  const priceNepal = {
    premium: {
      semiAnnual: "Rs. 15,000",
      yearly: "Rs. 24,000",
    },
    platinum: {
      semiAnnual: "Rs. 40,000",
      yearly: "Rs. 60,000",
    },
  };

  return (
    <AppLayout title="Pricing" rootClass="layout-1">
      {/*  Pricing Section Start  */}
      <NioSection className="pt-120 pt-lg-160">
        <div className="nk-pricing-wrap">
          <NioSection.Head alignX="center">
            <span className="d-inline-block fs-14 text-uppercase text-primary fw-semibold mb-2">
              Pricing &amp; Plans
            </span>
            <h2>Simple and Flexible Pricing</h2>
            <p className="fs-20 mb-0">
              No credit card required.Change or cancel your plan anytime.
            </p>
            <ul className="nk-btn-group flex-wrap justify-content-center pt-5">
              <li>
                <NioButton
                  href="/features"
                  className="btn-primary"
                  label="Explore All Features"
                />
              </li>
              <li>
                <NioButton
                  href="/contact-us"
                  className="btn-outline-primary"
                  label="Contact Support"
                />
              </li>
            </ul>
          </NioSection.Head>
          <div className={PricingStyle.container}>
            <div className="nk-pricing-action-wrap pt-5">
              <div className="nk-pricing-toggle nk-pricing-toggle-s1 text-primary-alt">
                <input
                  type="radio"
                  id="monthly"
                  name="duration"
                  value="Monthly"
                  checked={!isSemiAnnually}
                  onChange={toggleSwitch}
                  className="price-toggle-input"
                />
                <label
                  style={{
                    textAlign: "center",
                  }}
                  htmlFor="monthly"
                >
                  Semi-Annual
                </label>
                <input
                  id="yearly"
                  type="radio"
                  name="duration"
                  value="yearly"
                  checked={isSemiAnnually}
                  onChange={toggleSwitch}
                  className="price-toggle-input"
                />
                <label
                  style={{
                    textAlign: "center",
                  }}
                  htmlFor="yearly"
                >
                  Yearly
                </label>
                <span className="switch-highlight"></span>
              </div>
            </div>
          </div>
          <NioSection.Content>
            <Row className="gy-5">
              <Col md={6} lg={{ span: 4, order: 2 }}>
                <div className="nk-pricing nk-pricing-s3 mb-5 border p-5 h-100 active">
                  <div className="nk-pricing-head mb-3 mb-md-4">
                    <div className="nk-pricing-title-group mb-2">
                      <h4 className="title mb-0">Premium</h4>

                      <NioBadge
                        rounded
                        size="md"
                        label="Recomended"
                        icon="star-fill"
                        className="text-bg-white text-primary"
                      />
                    </div>

                    <div className="nk-month-amount amount-wrap mb-2 mt-auto">
                      <span className="amount h3 mb-0">
                        {!isSemiAnnually
                          ? priceNepal?.premium.semiAnnual
                          : priceNepal?.premium.yearly}
                      </span>
                      <span className="amount-text opacity-75">
                        / {!isSemiAnnually ? "6 month" : "year"}
                      </span>
                      <div>
                        <span className="amount-text opacity-75">
                          Billed Yearly
                        </span>
                      </div>
                    </div>
                    <p className="fs-18">
                      For medium teams with kitchen department and service.
                    </p>
                  </div>

                  <div className="nk-pricing-body">
                    <ul className="nk-list-link">
                      <li className="active">
                        <NioIcon name="check-circle-fill me-1" />
                        <span className="fs-18">
                          Everything in free plus 👇
                        </span>
                      </li>{" "}
                      <li className="active">
                        <NioIcon name="check-circle-fill me-1" />
                        <span className="fs-18">15 Staffs Logins</span>
                      </li>
                      <li className="active">
                        <NioIcon name="check-circle-fill me-1" />
                        <span className="fs-18">30 Tables and 500 Dishes</span>
                      </li>
                      <li className="active">
                        <NioIcon name="check-circle-fill me-1" />
                        <span className="fs-18">
                          Built in Delivery & Website
                        </span>
                      </li>
                      <li className="active">
                        <NioIcon name="check-circle-fill me-1" />
                        <span className="fs-18">Inventory and Accounts</span>
                      </li>
                      <li className="active">
                        <NioIcon name="check-circle-fill me-1" />
                        <span className="fs-18">Reports & Analytics</span>
                      </li>
                      <li className="active">
                        <NioIcon name="check-circle-fill me-1" />
                        <span className="fs-18">Custom User Role</span>
                      </li>
                      <li className="active">
                        <NioIcon name="check-circle-fill me-1" />
                        <span className="fs-18">24/7 Chat Support</span>
                      </li>
                    </ul>

                    <div className="pt-4">
                      <NioButton
                        href="/auth/sign-up"
                        className="btn-block btn-outline-primary"
                        label="Get Started"
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={{ span: 4, order: 1 }}>
                <div className="nk-pricing nk-pricing-s3 mb-5 border border-lighter p-5 h-100">
                  <div className="nk-pricing-head mb-3 mb-md-4">
                    <div className="nk-pricing-title-group mb-2">
                      <h4 className="title mb-0">Free Version</h4>
                    </div>
                    <div className="nk-month-amount amount-wrap mb-2 mt-auto">
                      <span
                        className="amount h3 mb-0"
                        style={{ color: "#da0819" }}
                      >
                        Free
                      </span>
                      <div>
                        {" "}
                        <span className="amount-text opacity-75">Forever</span>
                      </div>
                    </div>
                    <p className="fs-18">
                      Free for individual owners with:Food truck,small
                      bars,cafes,etc.
                    </p>
                  </div>

                  <div className="nk-pricing-body">
                    <ul className="nk-list-link">
                      <li className="active">
                        <NioIcon
                          name="check-circle text-primary"
                          className="me-1"
                        />
                        <span className="fs-18 text-dark">
                          Unlimited Orders
                        </span>
                      </li>
                      <li className="active">
                        <NioIcon
                          name="check-circle text-primary"
                          className="me-1"
                        />
                        <span className="fs-18 text-dark">2 staffs Logins</span>
                      </li>
                      <li className="active">
                        <NioIcon
                          name="check-circle text-primary"
                          className="me-1"
                        />
                        <span className="fs-18 text-dark">
                          5 Tables & 50 Dishes
                        </span>
                      </li>

                      <li className="active">
                        <NioIcon
                          name="check-circle text-primary"
                          className="me-1"
                        />
                        <span className="fs-18 text-dark">
                          Digital Menu(QR Code)
                        </span>
                      </li>
                      <li className="active">
                        <NioIcon
                          name="check-circle text-primary"
                          className="me-1"
                        />
                        <span className="fs-18 text-dark">Digital KOT</span>
                      </li>
                      <li className="active" style={{ visibility: "hidden" }}>
                        <NioIcon
                          name="check-circle text-primary"
                          className="me-1"
                        />
                        <span className="fs-18 text-dark">2 staffs Logins</span>
                      </li>
                      <li className="active" style={{ visibility: "hidden" }}>
                        <NioIcon
                          name="check-circle text-primary"
                          className="me-1"
                        />
                        <span className="fs-18 text-dark">2 staffs Logins</span>
                      </li>
                      <li className="active" style={{ visibility: "hidden" }}>
                        <NioIcon
                          name="check-circle text-primary"
                          className="me-1"
                        />
                        <span className="fs-18 text-dark">2 staffs Logins</span>
                      </li>
                    </ul>

                    <div className="pt-4">
                      <NioButton
                        href="/auth/sign-up"
                        className="btn-block btn-outline-primary"
                        label="Get Started"
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={{ span: 4, order: 3 }}>
                <div className="nk-pricing nk-pricing-s3 mb-5 border border-lighter p-5 h-100">
                  <div className="nk-pricing-head mb-3 mb-md-4">
                    <div className="nk-pricing-title-group mb-2">
                      <h4 className="title mb-0">Platinium</h4>
                    </div>
                    <div className="nk-month-amount amount-wrap pb-2 mt-auto">
                      <span
                        className="amount h3 mb-0 "
                        style={{ color: "#da0819" }}
                      >
                        {!isSemiAnnually
                          ? priceNepal?.platinum.semiAnnual
                          : priceNepal?.platinum?.yearly}
                      </span>
                      <span className="amount-tex opacity-75">
                        / {!isSemiAnnually ? "6 month" : "year"}
                      </span>
                      <div>
                        <span className="amount-tex opacity-75">
                          Billed Yearly
                        </span>
                      </div>
                    </div>
                    <p className="fs-18">
                      For big restaurents and cafe with kitchen department and
                      service.
                    </p>
                  </div>

                  <div className="nk-pricing-body">
                    <ul className="nk-list-link">
                      <li className="active">
                        <NioIcon
                          name="check-circle text-primary"
                          className="me-1"
                        />
                        <span className="fs-18 text-dark">
                          Everything in Premium plus 👇
                        </span>
                      </li>{" "}
                      <li className="active">
                        <NioIcon
                          name="check-circle text-primary"
                          className="me-1"
                        />
                        <span className="fs-18 text-dark">
                          Unlimited Staff Logins
                        </span>
                      </li>
                      <li className="active">
                        <NioIcon
                          name="check-circle text-primary"
                          className="me-1"
                        />
                        <span className="fs-18 text-dark">
                          Unlimited Table & Dishes
                        </span>
                      </li>
                      <li className="active">
                        <NioIcon
                          name="check-circle text-primary"
                          className="me-1"
                        />
                        <span className="fs-18 text-dark">
                          Attendence and Payroll
                        </span>
                      </li>{" "}
                      <li className="active">
                        <NioIcon
                          name="check-circle text-primary"
                          className="me-1"
                        />
                        <span className="fs-18 text-dark">
                          Custom Branding & Design
                        </span>
                      </li>
                      <li className="active">
                        <NioIcon
                          name="check-circle text-primary"
                          className="me-1"
                        />
                        <span className="fs-18 text-dark">Custom Website</span>
                      </li>
                      <li className="active">
                        <NioIcon
                          name="check-circle text-primary"
                          className="me-1"
                        />
                        <span className="fs-18 text-dark">
                          24/7 Chat & Call Support
                        </span>
                      </li>
                      <li className="active" style={{ visibility: "hidden" }}>
                        <NioIcon
                          name="check-circle text-primary"
                          className="me-1"
                        />
                        <span className="fs-18 text-dark">2 staffs Logins</span>
                      </li>
                    </ul>

                    <div className="pt-4">
                      <NioButton
                        href="/auth/sign-up"
                        className="btn-block btn-outline-primary"
                        label="Gets Started"
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </NioSection.Content>
        </div>
      </NioSection>

      {/* <NioSection className="pt-120 pt-lg-160">
        <div className="nk-pricing-wrap nk-pricing-wrap-s1 bg-blue-50">
          <NioSection.Head alignX="center" className="pb-md-7 pb-lg-120">
            <span className="d-inline-block fs-16 text-uppercase text-primary fw-semibold mb-2">
              Pricing Plan
            </span>
            <h2>Simple and Flexible Pricing</h2>
            <p className="fs-20">
              No credit Card required.Change or cancel your plan anytime.
            </p>
            <div className="d-flex">
              <ul className="d-flex gap-5">
                <li>
                  <span>
                    <Check />
                  </span>
                  Start for free
                </li>
                <li>
                  <span>
                    <Check />
                  </span>
                  No credit Card required
                </li>
                <li>
                  <span>
                    <Check />
                  </span>
                  Cancel anytime
                </li>
              </ul>
            </div>
            
          </NioSection.Head>
          <NioSection.Content>
            <div className="nk-pricing-group">
              {contents &&
                contents.map((content, idx) => (
                  <NioPricingCard
                    key={idx}
                    variant="one"
                    active={content.active}
                    features={content.body}
                    price={
                      !isSemiAnnually
                        ? content.heading.package.month.price
                        : content.heading.package.year.price
                    }
                    priceType={
                      !isSemiAnnually
                        ? content.heading.package.month.title
                        : content.heading.package.year.title
                    }
                    title={content?.heading.title}
                    subtitle={content?.heading.subtitle}
                  />
                ))}
            </div>
          </NioSection.Content>
        </div>
      </NioSection> */}
      {/*  Pricing Section Start  */}

      {/*  Compare-Plans Section Start  */}
      <NioSection className="pt-7 pt-lg-120" masks={["blur-1 right bottom"]}>
        <div className="nk-pricing-wrap">
          <NioSection.Head alignX="center">
            <span className="d-inline-block rounded-pill fs-14 text-uppercase text-bg-primary text-white fw-bold py-1 px-3 mb-2">
              COMPARE PLANS
            </span>
            <h2>Grow Your Restaurant.</h2>
            <p className="fs-20 mb-0 text-base">
              Unlock the full potential of your early-stage Restaurant with our
              tailored solutions and expert guidance to drive growth and
              success.
            </p>
          </NioSection.Head>
          <NioSection.Content>
            <div className="nk-compare table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="nk-compare-col lg empty-col"></th>
                    <th className="nk-compare-col empty-col center">
                      <div className="nk-compare-content">
                        <div className="overline-title small mb-3 text-light">
                          Free Version
                        </div>
                        <div className="amount h3 mb-4">Free Forever</div>
                        <NioButton
                          href="/auth/sign-up"
                          className="btn-outline-primary text-nowrap"
                          label=" Get Started"
                        />
                      </div>
                    </th>
                    <th className="nk-compare-col active empty-col center">
                      <div className="nk-compare-content">
                        <div className="overline-title small mb-3 text-light">
                          Premium
                        </div>
                        <div className="amount h3 mb-4">Rs 24,000 per year</div>
                        <NioButton
                          href="/auth/sign-up"
                          className="btn-primary text-nowrap"
                          label=" Get Started"
                        />
                      </div>
                    </th>
                    <th className="nk-compare-col empty-col center">
                      <div className="nk-compare-content">
                        <div className="overline-title small mb-3 text-light">
                          Platinium
                        </div>
                        <div className="amount h3 mb-4">Rs 60,000 per year</div>
                        <NioButton
                          href="/auth/sign-up"
                          className="btn-outline-primary text-nowrap"
                          label=" Get Started"
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="nk-compare-col lg">
                      <div className="nk-compare-content">
                        <h4 className="title">Analytics</h4>
                      </div>
                    </td>
                    <td className="nk-compare-col"></td>
                    <td className="nk-compare-col active"></td>
                    <td className="nk-compare-col"></td>
                  </tr>
                  <tr>
                    <td className="nk-compare-col lg">
                      <div className="nk-compare-content">
                        <p className="lead">Real-time analytics</p>
                      </div>
                    </td>
                    <td className="nk-compare-col center">
                      <div className="nk-compare-content">
                        <NioIcon
                          size="md"
                          name="check-circle"
                          className="text-primary"
                        />
                      </div>
                    </td>
                    <td className="nk-compare-col active center">
                      <div className="nk-compare-content">
                        <NioIcon
                          size="md"
                          name="check-circle-fill"
                          className="text-primary"
                        />
                      </div>
                    </td>
                    <td className="nk-compare-col center">
                      <div className="nk-compare-content">
                        <NioIcon
                          size="md"
                          name="check-circle"
                          className="text-primary"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="nk-compare-col lg">
                      <div className="nk-compare-content">
                        <p className="lead">Individual user analytics</p>
                      </div>
                    </td>
                    <td className="nk-compare-col center">
                      <div className="nk-compare-content">
                        <NioIcon
                          size="md"
                          name="check-circle"
                          className="text-primary"
                        />
                      </div>
                    </td>
                    <td className="nk-compare-col active center">
                      <div className="nk-compare-content">
                        <NioIcon
                          size="md"
                          name="check-circle-fill"
                          className="text-primary"
                        />
                      </div>
                    </td>
                    <td className="nk-compare-col center">
                      <div className="nk-compare-content">
                        <NioIcon
                          size="md"
                          name="check-circle"
                          className="text-primary"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="nk-compare-col lg">
                      <div className="nk-compare-content">
                        <p className="lead">Behaviour analytics</p>
                      </div>
                    </td>
                    <td className="nk-compare-col center"></td>
                    <td className="nk-compare-col active center">
                      <div className="nk-compare-content">
                        <NioIcon
                          size="md"
                          name="check-circle-fill"
                          className="text-primary"
                        />
                      </div>
                    </td>
                    <td className="nk-compare-col center">
                      <div className="nk-compare-content">
                        <NioIcon
                          size="md"
                          name="check-circle"
                          className="text-primary"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="nk-compare-col empty-col lg">
                      <div className="nk-compare-content">
                        <p className="lead">Data Predictions</p>
                      </div>
                    </td>
                    <td className="nk-compare-col empty-col center"></td>
                    <td className="nk-compare-col empty-col active center"></td>
                    <td className="nk-compare-col empty-col center">
                      <div className="nk-compare-content">
                        <NioIcon
                          size="md"
                          name="check-circle"
                          className="text-primary"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="nk-compare-col lg">
                      <div className="nk-compare-content">
                        <h4 className="title">Reporting</h4>
                      </div>
                    </td>
                    <td className="nk-compare-col"></td>
                    <td className="nk-compare-col active"></td>
                    <td className="nk-compare-col"></td>
                  </tr>
                  <tr>
                    <td className="nk-compare-col lg">
                      <div className="nk-compare-content">
                        <p className="lead">Daily reports</p>
                      </div>
                    </td>
                    <td className="nk-compare-col center">
                      <div className="nk-compare-content">
                        <NioIcon
                          size="md"
                          name="check-circle"
                          className="text-primary"
                        />
                      </div>
                    </td>
                    <td className="nk-compare-col active center">
                      <div className="nk-compare-content">
                        <NioIcon
                          size="md"
                          name="check-circle-fill"
                          className="text-primary"
                        />
                      </div>
                    </td>
                    <td className="nk-compare-col center">
                      <div className="nk-compare-content">
                        <NioIcon
                          size="md"
                          name="check-circle"
                          className="text-primary"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="nk-compare-col lg">
                      <div className="nk-compare-content">
                        <p className="lead">Weekly reports</p>
                      </div>
                    </td>
                    <td className="nk-compare-col center"></td>
                    <td className="nk-compare-col active center">
                      <div className="nk-compare-content">
                        <NioIcon
                          size="md"
                          name="check-circle-fill"
                          className="text-primary"
                        />
                      </div>
                    </td>
                    <td className="nk-compare-col center">
                      <div className="nk-compare-content">
                        <NioIcon
                          size="md"
                          name="check-circle"
                          className="text-primary"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="nk-compare-col lg">
                      <div className="nk-compare-content">
                        <p className="lead">Monthly reports</p>
                      </div>
                    </td>
                    <td className="nk-compare-col center"></td>
                    <td className="nk-compare-col active center">
                      <div className="nk-compare-content">
                        <NioIcon
                          size="md"
                          name="check-circle-fill"
                          className="text-primary"
                        />
                      </div>
                    </td>
                    <td className="nk-compare-col center">
                      <div className="nk-compare-content">
                        <NioIcon
                          size="md"
                          name="check-circle"
                          className="text-primary"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="nk-compare-col empty-col lg">
                      <div className="nk-compare-content">
                        <p className="lead">Custom reports</p>
                      </div>
                    </td>
                    <td className="nk-compare-col empty-col center"></td>
                    <td className="nk-compare-col empty-col active center">
                      <div className="nk-compare-content">
                        <NioIcon
                          size="md"
                          name="check-circle-fill"
                          className="text-primary"
                        />
                      </div>
                    </td>
                    <td className="nk-compare-col empty-col center"></td>
                  </tr>
                  <tr>
                    <td className="nk-compare-col lg">
                      <div className="nk-compare-content">
                        <h4 className="title">Security &amp; Privacy</h4>
                      </div>
                    </td>
                    <td className="nk-compare-col"></td>
                    <td className="nk-compare-col active"></td>
                    <td className="nk-compare-col"></td>
                  </tr>
                  <tr>
                    <td className="nk-compare-col lg">
                      <div className="nk-compare-content">
                        <p className="lead">Two-Factor authentication</p>
                      </div>
                    </td>
                    <td className="nk-compare-col center"></td>
                    <td className="nk-compare-col active center"></td>
                    <td className="nk-compare-col center">
                      <div className="nk-compare-content">
                        <NioIcon
                          size="md"
                          name="check-circle"
                          className="text-primary"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="nk-compare-col lg">
                      <div className="nk-compare-content">
                        <p className="lead">SAML-based single sign-on SS</p>
                      </div>
                    </td>
                    <td className="nk-compare-col center"></td>
                    <td className="nk-compare-col active center"></td>
                    <td className="nk-compare-col center">
                      <div className="nk-compare-content">
                        <NioIcon
                          size="md"
                          name="check-circle"
                          className="text-primary"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="nk-compare-col lg">
                      <div className="nk-compare-content">
                        <p className="lead">Complience</p>
                      </div>
                    </td>
                    <td className="nk-compare-col center"></td>
                    <td className="nk-compare-col active center"></td>
                    <td className="nk-compare-col center">
                      <div className="nk-compare-content">
                        <NioIcon
                          size="md"
                          name="check-circle"
                          className="text-primary"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="nk-compare-col empty-col lg">
                      <div className="nk-compare-content">
                        <p className="lead">CCPA complient processing</p>
                      </div>
                    </td>
                    <td className="nk-compare-col empty-col center"></td>
                    <td className="nk-compare-col empty-col active center"></td>
                    <td className="nk-compare-col empty-col center">
                      <div className="nk-compare-content">
                        <NioIcon
                          size="md"
                          name="check-circle"
                          className="text-primary"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </NioSection.Content>
        </div>
      </NioSection>
      {/*  Compare-Plans Section End  */}

      {/*  CTA Section Start  */}
      <NioSection>
        <NioSection.Content>
          <div
            className="nk-cta-wrap rounded-3 is-theme p-5 p-lg-7"
            style={{ backgroundColor: "#DA0819" }}
          >
            <Row className="g-gs align-items-center">
              <Col lg={8}>
                <div className="media-group flex-column flex-lg-row align-items-center ">
                  <NioMedia
                    rounded
                    size="lg"
                    variant="white"
                    icon="bar-chart-fill"
                    className=" text-primary mb-2 mb-lg-0 me-lg-1"
                  />
                  <div className="media-text text-center text-lg-start">
                    <h3 className="m-0">Need A Custom Plan ?</h3>
                    <p className="fs-16">
                      We provide top notch custom solution for businesses to get
                      started.
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={4} className="text-center text-lg-end">
                <NioButton
                  href="/contact-us"
                  className="btn-white text-dark"
                  label="Contact Support"
                />
              </Col>
            </Row>
          </div>
        </NioSection.Content>
      </NioSection>
      {/*  CTA Section End  */}

      {/*  Newsletter Section Start  */}
      <NioSection className="nk-newsletter-section pb-lg-0">
        <Row className="justify-content-center justify-content-lg-between align-items-center pb-5 border-bottom border-lighter">
          <Col lg={6} xl={4}>
            <div className="nk-newsletter-content text-center text-lg-start pb-5 pb-lg-0">
              <h4 className="text-capitalize">Subscribe to our newsletter</h4>
              <p className="fs-16">
                Join the 5000+ People That Uses Softnio Products.
              </p>
            </div>
          </Col>
          <Col md={10} lg={6} xl={5}>
            <NioSubscribeField variant="one" />
          </Col>
        </Row>
      </NioSection>
      {/*  Newsletter Section End  */}
    </AppLayout>
  );
}
