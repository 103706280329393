import React from "react";
import { Col, Row } from "react-bootstrap";
import Playstore1 from "../../../assets/images/playstore.png";
import Playstore2 from "../../../assets/images/playstore1.png";

// layout
import AppLayout from "../../../layouts/AppLayout/AppLayout";

// components
import {
  NioSection,
  NioSubscribeField,
  NioButton,
  NioMedia,
  NioCard,
} from "../../../components";

// section content
import TestimonialContent from "../../../components/PageComponents/InnerPages/Features/TestimonialContent/TestimonialContent";
import { Link } from "react-router-dom";

function index() {
  return (
    <AppLayout
      title="Features"
      variant={2}
      //  rootClass="layout-1"
    >
      {/*  Discover Section Start  */}
      <NioSection
        className="pt-120 pt-lg-160 pb-7 pb-lg-120 overflow-hidden"
        masks={[" blur-1 left top", " blur-1 right bottom"]}
      >
        <NioSection.Head alignX="center">
          <span className="d-inline-block fs-14 text-uppercase text-primary fw-semibold mb-2">
            Discover our Features
          </span>
          <h2>
            All The <span style={{ color: "#DA0819" }}>Features</span> You
            Expect,{" "}
            <span className="d-lg-block">
              Even <span style={{ color: "#DA0819" }}>More</span>.
            </span>
          </h2>
          <p className="fs-20 mb-0">
            {" "}
            With clear,and high sales reports,use our system to stay on top of
            your cashflow.Manage your customer's orders and sales summary
            reports from any location and at any time.{" "}
          </p>
          <ul className="nk-btn-group justify-content-center pt-5">
            <li>
              <a
                href="https://apps.apple.com/np/app/restrox-restaurant-manager/id1611549881"
                target="_blank"
              >
                {" "}
                <img
                  src={Playstore2}
                  style={{
                    width: " 160.86px",
                    height: " 48px",
                    borderRadius: " 10.38px",
                  }}
                />
              </a>{" "}
              <a
                className="mx-2"
                href="https://play.google.com/store/search?q=restrox&c=apps"
                target="_blank"
              >
                <img
                  src={Playstore1}
                  style={{
                    width: " 160.86px",
                    height: " 48px",
                    borderRadius: " 10.38px",
                  }}
                />
              </a>
              <NioButton
                href="/auth/login"
                className="btn-outline-primary"
                label="Sign Up For Free"
              />
            </li>
          </ul>
        </NioSection.Head>
      </NioSection>
      {/*  Discover Section End  */}

      <section className="nk-banner nk-banner-collab">
        <div className="nk-banner-grids container position-relative  px-lg-3">
          <div className="nk-banner-grids-content">
            <div className="left">
              <img
                src="images/collaboration-tool/grid-cover-1.png"
                alt="grid-img"
                className="shadow-xl rounded-4 h-400"
              />
              <img
                src="images/collaboration-tool/grid-cover-2.png"
                alt="grid-img"
                className="shadow-xl rounded-4 h-400"
              />
            </div>
            <div className="right">
              <div className="right-row right-row-one">
                <img
                  src="images/collaboration-tool/grid-cover-3.png"
                  alt="grid-img"
                  className="shadow-xl rounded-4 h-500"
                />
                <img
                  src="images/collaboration-tool/grid-cover-4.png"
                  alt="grid-img"
                  className="shadow-xl rounded-4 h-500"
                />
              </div>
              <div className="right-row right-row-two">
                <img
                  src="images/collaboration-tool/grid-cover-5.png"
                  alt="grid-img"
                  className="shadow-xl rounded-4 h-88"
                />
              </div>
              <div className="right-row right-row-three">
                <img
                  src="images/collaboration-tool/grid-cover-6.png"
                  alt="grid-img"
                  className="shadow-xl rounded-4 h-185"
                />
                <img
                  src="images/collaboration-tool/grid-cover-7.png"
                  alt="grid-img"
                  className="shadow-xl rounded-4 h-185"
                />
              </div>
            </div>
          </div>
          <img
            src="images/collaboration-tool/grid-cover.png"
            className="nk-banner-grids-alter"
            alt="cover-grids"
          />
        </div>
      </section>

      {/*  Banner Section End   */}

      {/*  Features Section Start  ssss*/}
      <NioSection className="pb-7 pb-lg-120 mt-5">
        <NioSection.Content>
          <Row className="gy-5 gy-lg-0 align-items-center">
            <Col sm={6} lg={4}>
              <div className="nk-feature-card-text line-1">
                <div className="nk-feature-card-text-body">
                  <h5 className="title fw-normal">
                    Visualize &amp; break down{" "}
                    <span className="fw-semibold d-inline-block d-sm-block">
                      engagement trends
                    </span>
                  </h5>
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div className="nk-feature-card-text line-2">
                <div className="nk-feature-card-text-body">
                  <h5 className="title fw-normal">
                    <span className="fw-semibold d-inline-block d-sm-block">
                      Build funnels &amp; find key
                    </span>{" "}
                    retention drivers
                  </h5>
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div className="nk-feature-card-text line-3">
                <div className="nk-feature-card-text-body">
                  <h5 className="title fw-normal">
                    See the impact of{" "}
                    <span className="fw-semibold d-inline-block d-sm-block">
                      product launches &amp; experiments
                    </span>
                  </h5>
                </div>
              </div>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Features Section End  */}

      {/*  Feature Section Start   */}
      <NioSection className="nk-feature-section bg-purple-50">
        <NioSection.Head alignX="center">
          <h2>
            <span className="text-indigo">Features</span> That Get You Results
          </h2>
          <p className="fs-20 mb-0">
            Unlock features that deliver tangible results, driving success,
            growth, and achieving your desired outcomes.
          </p>
          <ul className="nk-btn-group justify-content-center pt-5">
            <li>
              <NioButton
                href="/features"
                className="btn-indigo"
                label="Explore All"
              />
            </li>
            <li>
              <NioButton
                href="#"
                className="btn-outline-indigo"
                label="Learn More"
              />
            </li>
          </ul>
        </NioSection.Head>
        <NioSection.Content>
          <Row className="gy-5 gy-md-7">
            <Col sm={6} md={4} lg={3}>
              <div className="text-center">
                <NioMedia
                  size="xl"
                  variant="indigo-200"
                  className="mb-5"
                  icon="bar-chart-fill"
                />
                <h4>Live Collaboration</h4>
              </div>
            </Col>
            <Col sm={6} md={4} lg={3}>
              <div className="text-center">
                <NioMedia
                  size="xl"
                  variant="orange-100"
                  className="mb-5"
                  icon="layout-fill"
                />
                <h4>1M+ Templates</h4>
              </div>
            </Col>
            <Col sm={6} md={4} lg={3}>
              <div className="text-center">
                <NioMedia
                  size="xl"
                  variant="blue-200"
                  className="mb-5"
                  icon="folder-fill"
                />
                <h4>Searchable Assets</h4>
              </div>
            </Col>
            <Col sm={6} md={4} lg={3}>
              <div className="text-center">
                <NioMedia
                  size="xl"
                  variant="green-200"
                  className="mb-5"
                  icon="star-fill"
                />
                <h4>Unlimited Reviews</h4>
              </div>
            </Col>
            <Col sm={6} md={4} lg={3}>
              <div className="text-center">
                <NioMedia
                  size="xl"
                  variant="yellow-200"
                  className="mb-5"
                  icon="bell-fill"
                />
                <h4>Notifications</h4>
              </div>
            </Col>
            <Col sm={6} md={4} lg={3}>
              <div className="text-center">
                <NioMedia
                  size="xl"
                  variant="purple-200"
                  className="mb-5"
                  icon="heart-fill"
                />
                <h4>Responsive Design</h4>
              </div>
            </Col>
            <Col sm={6} md={4} lg={3}>
              <div className="text-center">
                <NioMedia
                  size="xl"
                  variant="red-200"
                  className="mb-5"
                  icon="layer-fill"
                />
                <h4>Media Support</h4>
              </div>
            </Col>
            <Col sm={6} md={4} lg={3}>
              <div className="text-center">
                <NioMedia
                  size="xl"
                  variant="cyan-200"
                  className="mb-5"
                  icon="user-group-fill"
                />
                <h4>Version History</h4>
              </div>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Feature Section End   */}

      {/*  Products Section Start   */}
      <NioSection className="nk-product-section overflow-hidden pb-7 pb-lg-120">
        <NioSection.Content className="d-flex flex-column gap-7 gap-xl-120">
          <Row className="align-items-center flex-row-reverse justify-content-between">
            <Col lg={6}>
              <div className="nk-feature-overview-img mb-5 mb-lg-0">
                <img
                  src="images/collaboration-tool/section-cover-1.png"
                  alt="cover-bg"
                  className="base"
                />
                <img
                  src="images/collaboration-tool/section-cover-1-a.png"
                  alt="cover-fd"
                  className="children children-rs children-4 h-240 shadow-xl rounded-3 img-fluid animate animate-shakeY animate-duration-12 "
                />
              </div>
            </Col>
            <Col lg={6} xl={5}>
              <div>
                <div className="pb-5 pb-lg-7 ">
                  <NioMedia
                    size="lg"
                    variant="indigo text-white"
                    className="mb-5 rounded-3"
                    icon="user-group-fill"
                  />
                  <div>
                    <h2>Multiple Restaurants, Multiple Roles, One App!.</h2>
                  </div>
                </div>
                <ul className="nk-timeline">
                  <li className="nk-timeline-item">
                    <div className="nk-timeline-item-inner">
                      <div className="nk-timeline-symbol text-bg-indigo text-white"></div>
                      <div className="nk-timeline-content mt-n1">
                        <p>
                          With a more secure hierarchy, it allows access to
                          confidential and non-confidential information based on
                          your responsibilities in the restaurant.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="nk-timeline-item">
                    <div className="nk-timeline-item-inner">
                      <div className="nk-timeline-symbol text-bg-indigo text-white"></div>
                      <div className="nk-timeline-content mt-n1">
                        <p>
                          It’ easy to manage your chain restaurants. Monitor
                          your restaurant's activity from anywhere through a
                          single system.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul className="nk-btn-group pt-5">
                  <li>
                    <NioButton
                      href="/auth/sign-up"
                      className="btn-indigo"
                      label="Create Account"
                    />
                  </li>
                  <li>
                    <NioButton
                      href="#"
                      className="btn-outline-indigo"
                      label="Learn More"
                    />
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-between">
            <Col lg={6}>
              <div className="nk-feature-overview-img mb-7 mb-lg-0">
                <img
                  src="images/collaboration-tool/section-cover-2.png"
                  alt="cover-bg"
                  className="base"
                />
                <img
                  src="images/collaboration-tool/section-cover-2-a.png"
                  alt="cover-fd"
                  className="children children-rs  children-right-bottom rounded-4 h-400 animate animate-shakeY animate-duration-12 animate-delay-1 "
                />
              </div>
            </Col>
            <Col lg={6} xl={5}>
              <div>
                <div className="pb-5 pb-lg-7">
                  <NioMedia
                    size="lg"
                    variant="danger text-white"
                    className="mb-5 rounded-3"
                    icon="user-group-fill"
                  />
                  <h2>Give your team the tools they need to work smarter</h2>
                </div>
                <ul className="nk-timeline">
                  <li className="nk-timeline-item">
                    <div className="nk-timeline-item-inner">
                      <div className="nk-timeline-symbol text-bg-danger text-white"></div>
                      <div className="nk-timeline-content mt-n1">
                        <p>
                          RestroX is an all-in-one restaurant management app
                          that can adapt to businesses of all sizes and kinds.
                          This means that whether you are a small café or a
                          large chain restaurant, RestroX can help you manage
                          your business more efficiently and effectively.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="nk-timeline-item">
                    <div className="nk-timeline-item-inner">
                      <div className="nk-timeline-symbol text-bg-danger text-white"></div>
                      <div className="nk-timeline-content mt-n1">
                        <p>
                          RestroX is a full-service restaurant operating system
                          with a cloud-based infrastructure. This means that you
                          can access RestroX from anywhere in the world, and
                          your data is always safe and secure.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="nk-timeline-item">
                    <div className="nk-timeline-item-inner">
                      <div className="nk-timeline-symbol text-bg-danger text-white"></div>
                      <div className="nk-timeline-content mt-n1">
                        <p>
                          RestroX has industry-leading integrations and
                          features. This means that RestroX can help you
                          streamline your operations, improve your customer
                          service, and increase your profits.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul className="nk-btn-group pt-5">
                  <li>
                    <NioButton
                      href="/auth/sign-up"
                      className="btn-danger text-white"
                      label="Create Account"
                    />
                  </li>
                  <li>
                    <NioButton
                      href="#"
                      className="btn-outline-danger"
                      label="Learn More"
                    />
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="flex-row-reverse align-items-center justify-content-between">
            <Col lg={6}>
              <div className="nk-feature-overview-img mb-5 mb-lg-0">
                <img
                  src="images/collaboration-tool/section-cover-3.png"
                  alt="cover-bg"
                  className="base"
                />
                <img
                  src="images/collaboration-tool/section-cover-3-a.png"
                  alt="cover-fd"
                  className="children children-rs  children-left-top h-400 animate animate-shakeY animate-duration-12 animate-delay-2"
                />
              </div>
            </Col>
            <Col lg={6} xl={5}>
              <div>
                <div className="pb-5 pb-lg-7 ">
                  <NioMedia
                    size="lg"
                    variant="indigo text-white"
                    className="mb-5 rounded-3"
                    icon="user-group-fill"
                  />
                  <h2>Simplify your online ordering experience</h2>
                </div>
                <ul className="nk-timeline">
                  <li className="nk-timeline-item">
                    <div className="nk-timeline-item-inner">
                      <div className="nk-timeline-symbol text-bg-indigo text-white"></div>
                      <div className="nk-timeline-content mt-n1">
                        <p>
                          Help customers easily order their favorite menu items
                          with ease and take orders through the app from
                          anywhere and from any device, anytime.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="nk-timeline-item">
                    <div className="nk-timeline-item-inner">
                      <div className="nk-timeline-symbol text-bg-indigo text-white"></div>
                      <div className="nk-timeline-content mt-n1">
                        <p>
                          With RestroX, you can manage and update menu items and
                          take orders easily.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="nk-timeline-item">
                    <div className="nk-timeline-item-inner">
                      <div className="nk-timeline-symbol text-bg-indigo text-white"></div>
                      <div className="nk-timeline-content mt-n1">
                        <p>
                          Enhance your customer experience with QR and digital
                          menu solutions.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul className="nk-btn-group pt-5">
                  <li>
                    <NioButton
                      href="/auth/sign-up"
                      className="btn-indigo"
                      label="Create Account"
                    />
                  </li>
                  <li>
                    <NioButton
                      href="#"
                      className="btn-outline-indigo"
                      label="Learn More"
                    />
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Products Section End   */}

      {/*  Testimonial Section Start  */}
      {/* <NioSection className="bg-primary-gradient is-theme">
        <NioSection.Content>
          <TestimonialContent />
        </NioSection.Content>
      </NioSection> */}
      {/*  Testimonial Section End  */}

      {/*  Values Section Start  */}
      <NioSection masks={["blur-1 left center"]}>
        <NioSection.Head alignX="center">
          <span className="d-inline-block fs-14 text-uppercase text-primary fw-semibold mb-2">
            our values
          </span>
          <h2 className="mb-0">
            Learn About Other <span style={{ color: "#DA0819" }}> RestroX</span>
            <br /> Cores & <span style={{ color: "#DA0819" }}>Values</span>
          </h2>
          <ul className="nk-btn-group justify-content-center pt-5">
            <li>
              <NioButton href="#" className="btn-primary" label="Get Started" />
            </li>
            <li>
              <NioButton
                href="#"
                className="btn-outline-primary"
                label="Learn More"
              />
            </li>
          </ul>
        </NioSection.Head>
        <NioSection.Content>
          <Row className="gy-5">
            <Col md={6} lg={4}>
              <NioCard>
                <NioCard.Body>
                  <NioMedia
                    size="lg"
                    rounded
                    variant="primary-soft"
                    icon="file-text-fill"
                    className="mb-4"
                  />
                  <h4>Safe & Secure</h4>
                  <p>
                    A retention report provides valuable insights into customer
                    or employee retention rates, helping organizations measure
                    and improve.
                  </p>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4}>
              <NioCard>
                <NioCard.Body>
                  <NioMedia
                    size="lg"
                    rounded
                    variant="info-soft"
                    icon="pie-fill"
                    className="mb-4"
                  />
                  <h4>Stable & Scalable</h4>
                  <p>
                    Our efforts consistently deliver impactful results, making a
                    tangible difference in achieving our goals and objectives.
                  </p>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4}>
              <NioCard>
                <NioCard.Body>
                  <NioMedia
                    size="lg"
                    rounded
                    variant="warning-soft"
                    icon="color-palette-fill"
                    className="mb-4"
                  />
                  <h4>Fast Performance</h4>
                  <p>
                    A/B test variants are distinct versions of a webpage or
                    element used to compare user engagement and performance.
                  </p>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4}>
              <NioCard>
                <NioCard.Body>
                  <NioMedia
                    size="lg"
                    rounded
                    variant="danger-soft"
                    icon="view-grid-wd"
                    className="mb-4"
                  />
                  <h4>Useful & Creative Features</h4>
                  <p>
                    Limitless segmentation allows for the categorization of data
                    without constraints, enabling precise targeting and analysis
                    across.
                  </p>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4}>
              <NioCard>
                <NioCard.Body>
                  <NioMedia
                    size="lg"
                    rounded
                    variant="primary-soft"
                    icon="bell-fill"
                    className="mb-4"
                  />
                  <h4>Continuous Growth</h4>
                  <p>
                    The team dashboard provides real-time insights and triggers
                    alerts, enhancing collaboration and keeping teams informed
                    for.
                  </p>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4}>
              <NioCard>
                <NioCard.Body>
                  <NioMedia
                    size="lg"
                    rounded
                    variant="success-soft"
                    icon="puzzle-fill"
                    className="mb-4"
                  />
                  <h4>Best Service</h4>
                  <p>
                    An integration directory centralizes and organizes
                    connections between various software systems, streamlining
                    data exchange.
                  </p>
                </NioCard.Body>
              </NioCard>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Values Section End  */}

      {/*  Device Section Start  */}
      <NioSection masks={["blur-1 right center"]}>
        <NioSection.Head alignX="center">
          <span className="d-inline-block fs-14 text-uppercase text-primary fw-semibold mb-2">
            responsive on every device
          </span>
          <h2>Over 4,000+ Users love us. They can’t be wrong!</h2>
          <ul className="nk-btn-group justify-content-center pt-5">
            <li>
              <NioButton href="#" className="btn-primary" label="Get Started" />
            </li>
            <li>
              <NioButton
                href="/customer-testimonials"
                className="btn-outline-primary"
                label="Customer Reviews"
              />
            </li>
          </ul>
        </NioSection.Head>
        <NioSection.Content>
          <div className="nk-responsive-img text-center">
            <img src="images/thumb/o.png" alt="" className="w-100" />
          </div>
        </NioSection.Content>
      </NioSection>
      {/*  Device Section End  */}

      {/*  Learn-More Section Start  */}
      <NioSection>
        <NioSection.Head alignX="center">
          <span className="d-inline-block fs-14 text-uppercase text-primary fw-semibold mb-2">
            learn more
          </span>
          <h2>#1 Best Restaurent Software</h2>
          <h2 style={{ color: "#DA0819" }}>RestroX</h2>
        </NioSection.Head>
        <NioSection.Content>
          <Row className="gy-5">
            <Col lg={6}>
              <NioCard className="nk-feature-block overflow-hidden border-0 bg-blue-50 rounded-2 h-100">
                <NioCard.Body>
                  <div className="nk-feature-block-content h-100">
                    <NioMedia
                      size="lg"
                      rounded
                      variant="primary"
                      icon="users-fill"
                      className="mb-3 mb-md-5"
                    />
                    <h4>Get To Know Our Amazing Team</h4>
                    <p className="fs-18 mb-0">
                      Take a closer look at our outstanding team members, their
                      diverse skills, and collective dedication to delivering
                      excellence, driving innovation, and ensuring your
                      satisfaction with our products.
                    </p>
                    <NioButton
                      href="/about"
                      className="btn-link text-primary pt-5"
                      label="About Us"
                      icon="arrow-right after"
                    />
                  </div>
                </NioCard.Body>
                <div className="text-start">
                  <img
                    src="images/features/a.png"
                    alt="feature"
                    className="pe-3 pe-md-7"
                  />
                </div>
              </NioCard>
            </Col>
            <Col lg={6}>
              <NioCard className="nk-feature-block overflow-hidden border-0 bg-blue-50 rounded-2 h-100">
                <NioCard.Body>
                  <div className="nk-feature-block-content h-100">
                    <NioMedia
                      size="lg"
                      rounded
                      variant="danger text-white"
                      icon="help-fill"
                      className="mb-3 mb-md-5"
                    />
                    <h4>Questions? Go to help center for info!</h4>
                    <p className="fs-18 mb-0">
                      For any queries or information, please refer to our Help
                      Center. It offers comprehensive resources to address your
                      questions and provide detailed information about our
                      products and services.
                    </p>
                    <NioButton
                      href="/about"
                      className="btn-link text-primary pt-5"
                      label="Help Center"
                      icon="arrow-right after"
                    />
                  </div>
                </NioCard.Body>
                <div className="text-end">
                  <img
                    src="images/features/b.png"
                    alt="feature"
                    className="ps-3 ps-md-7"
                  />
                </div>
              </NioCard>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Learn-More Section End  */}

      {/*  CTA Section Start  */}
      <NioSection>
        <NioSection.Content>
          <div className="nk-cta-wrap nk-cta-card bg-primary-gradient rounded-3 is-theme position-relative overflow-hidden">
            <Row className="g-0 align-items-center overflow-hidden">
              <Col xs={{ order: 2 }} lg={{ span: 7, order: 0 }}>
                <div className="nk-block-head-content mb-0">
                  <h2 className="newsletter-heading text-capitalize h1 m-0 mb-4 mb-lg-7">
                    Start tracking your user analytics to boost your business
                  </h2>
                  <ul className="nk-btn-group flex-wrap ps-1">
                    <li>
                      <NioButton
                        href="#"
                        className="btn-lg btn-white text-dark"
                        label="Get Started"
                      />
                    </li>
                    <li>
                      <NioButton
                        href="/pricing"
                        className="btn-lg btn-outline-white"
                        label="Pricing &amp; Plans"
                      />
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={{ order: 1 }} lg={{ span: 5, order: 0 }}>
                <div className="nk-cta-img-wrap text-end  ps-5 pt-7 pt-lg-0 ps-sm-6 ps-lg-0">
                  <img
                    src="images/thumb/c.png"
                    alt="tracking-thumb"
                    className="me-n1"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </NioSection.Content>
      </NioSection>
      {/*  CTA Section End  */}

      {/*  Newsletter Section Start  */}
      <NioSection className="nk-newsletter-section pb-lg-0">
        <Row className="justify-content-center justify-content-lg-between align-items-center pb-5 border-bottom border-lighter">
          <Col lg={6} xl={4}>
            <div className="nk-newsletter-content text-center text-lg-start pb-5 pb-lg-0">
              <h4 className="text-capitalize">Subscribe to our newsletter</h4>
              <p className="fs-16">
                Join the 5000+ People That Uses Softnio Products.
              </p>
            </div>
          </Col>
          <Col md={10} lg={6} xl={5}>
            <NioSubscribeField variant="one" />
          </Col>
        </Row>
      </NioSection>
      {/*  Newsletter Section End  */}
    </AppLayout>
  );
}

export default index;
